//https://github.com/walkermatt/ol-layerswitcher#grouplayeroptions
//https://github.com/walkermatt/ol-layerswitcher#layerswitcher
//https://github.com/walkermatt/ol-layerswitcher#parcel-webpack-etc
import 'ol/ol.css';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';
import ImageWMS from 'ol/source/ImageWMS';
import TileWMS from 'ol/source/TileWMS';
import XYZ from 'ol/source/XYZ';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import View from 'ol/View';
import {Image as ImageLayer, Tile as TileLayer} from 'ol/layer';
import {ZoomToExtent, defaults as defaultControls} from 'ol/control';
import MousePosition from 'ol/control/MousePosition';
import LayerSwitcher from 'ol-layerswitcher';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import LayerGroup from 'ol/layer/Group';
import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import {ZoomSlider} from 'ol/control';
import {ScaleLine} from 'ol/control';
import {createStringXY} from 'ol/coordinate';
import {FullScreen} from 'ol/control';
import TileGrid from 'ol/tilegrid/TileGrid';
import Attribution from 'ol/control/Attribution';


import * as olProj from 'ol/proj';
import {addCoordinateTransforms} from 'ol/proj';
import {get as getProjection} from 'ol/proj';

import {getTopLeft, getWidth} from 'ol/extent';

var projection = getProjection('EPSG:3857');
var projectionExtent = projection.getExtent();
var size = getWidth(projectionExtent) / 256;
var resolutions = new Array(14);
var matrixIds = new Array(14);
for (var z = 0; z < 24; ++z) {
	// generate resolutions and matrixIds arrays for this WMTS
	resolutions[z] = size / Math.pow(2, z);
	matrixIds[z] = z;
}
var attributions = '<a href="https://www.norkart.no/" target="_blank">Norkart AS</a>'
//attributions = '<div><img id="legend0"/></div><div><img id="legend1"/></div><div><img id="legend2"/></div><div><img id="legend3"/></div><div><img id="legend4"/></div>'


const osm = new TileLayer({
	source: new OSM(),
	title:'OpenStreetMap',
	type: 'base',
	visible: true,
} );

const orthoophoto = new TileLayer({
	title:'Orthophoto Tiles',
	type: 'base',
	source: new XYZ({
		attributions:attributions,
		//projection: projection,
		projection: 'EPSG:3857',
		//tilegrid: new TileGrid({origin: getTopLeft(projectionExtent),resolutions: resolutions,matrixIds: matrixIds,}),
		//tilegrid: new TileGrid({resolutions: resolutions,extent: projectionExtent,}),
		//url:'http://waapi.webatlas.no/wms-orto/tms/ortofoto_25833/{z}/{x}/{y}.jpg' + '?api_key=53889552-c207-4395-bf4f-9901c0efdad8',
		//url:'http://waapi.webatlas.no/wms-orto/tms/ortofoto_25833/{z}/{x}/{y}.jpg' + '?api_key=53889552-c207-4395-bf4f-9901c0efdad8',
		//url:'https://maptiles.finncdn.no/tileService/1.0.3/norortho/{z}/{x}/{y}.jpg'
		url:'https://waapi.webatlas.no/maptiles/tiles/webatlas-orto-newup/wa_grid/{z}/{x}/{y}.jpeg' + '?api_key=f2f69fcd-602c-4015-86ae-8592b888a847'
		}),
	})

const webatlas_gray_cache = new TileLayer({
	title:'Webatlas Gray Tiles',
	type: 'base',
	source: new XYZ({
		attributions:attributions,
		projection: 'EPSG:3857',
		url:'https://www.webatlas.no/maptiles/tiles/webatlas-gray-vektor/wa_grid/{z}/{x}/{y}.png' //+ '?apikey=Your API key from http://www.thunderforest.com/docs/apikeys/ here',
		}),
	})

const webatlas_gray = new TileLayer({
	title:'Webatlas Gray WMS',
	type: 'base',
	source: new TileWMS({
		attributions:attributions,
		params: {'LAYERS': '​​​​wms-vektor-standard:Norge','api_key':'36521fd9-1fdd-4fe2-8958-cfb489bc17c6'},
		url:'https://waapi.webatlas.no/wms-vektor-gray/',
		//url: 'waapi.webatlas.no/maptiles/tiles/webatlas-gray-vektor/wa_grid/',
		ratio: 1,
		//layer: 'wms-vektor-standard:Norge',
		//matrixSet: 'EPSG:3857',
		//format: 'image/png',
		//projection: 'EPSG:3857',
		//tileGrid: new WMTSTileGrid({origin: getTopLeft(projectionExtent),resolutions: resolutions,matrixIds: matrixIds,}),
		//style: 'default',
		//wrapX: true,
	}),
})

const webatlas = new TileLayer({
	//extent: [-13884991, 2870341, -7455066, 6338219],
	title:'Webatlas Standard WMS',
	type: 'base',
	source: new TileWMS({
		attributions:attributions,
		//url: 'http://192.168.4.177:8080/geoserver/wms-vektor-standard/wms',
		url:'https://waapi.webatlas.no/wms-vektor-standard/',
		params: {'LAYERS': '​​​​wms-vektor-standard:Norge','env':'dybde:yes','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847'},
		ratio: 1,
		serverType: 'geoserver',
	})
})

var image_wms = new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS': 'wms-havvind:havvind_kandidater_land','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847'},
	ratio: 1,
	serverType: 'geoserver'
	})

const havvind_kaibrygger = new ImageLayer({
	//extent: [-13884991, 2870341, -7455066, 6338219],
	title:'Kaier',
	source: new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS': 'wms-havvind:havvind_kaibrygger','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847'},
	ratio: 1,
	serverType: 'geoserver',
	visible: true,
	})
})

const havvind_kandidater_land_scenario_1_v2 = new ImageLayer({
	//extent: [-13884991, 2870341, -7455066, 6338219],
	title:'Kandidater land',
	source: new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS': 'wms-havvind:havvind_kandidater_land_scenario_1_v2','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847'},
	ratio: 1,
	serverType: 'geoserver',
	visible: true,
	})
})

const havvind_kandidater_land_scenario_2_v2 = new ImageLayer({
	//extent: [-13884991, 2870341, -7455066, 6338219],
	title:'Kandidater land',
	source: new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS': 'wms-havvind:havvind_kandidater_land_scenario_2_v2','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847'},
	ratio: 1,
	serverType: 'geoserver',
	visible: true,
	})
})

const havvind_tettsteder = new ImageLayer({
	//extent: [-13884991, 2870341, -7455066, 6338219],
	title:'Tettsteder',
	source: new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS': 'wms-havvind:havvind_tettsteder','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847'},
	ratio: 1,
	serverType: 'geoserver',
	visible: true,
	})
})

const havvind_flyplasser = new ImageLayer({
	//extent: [-13884991, 2870341, -7455066, 6338219],
	title:'Flyplasser',
	source: new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS': 'wms-havvind:havvind_flyplasser','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847'},
	ratio: 1,
	serverType: 'geoserver',
	visible: true,
	})
})

const havvind_flyplasser_kjoretid = new ImageLayer({
	//extent: [-13884991, 2870341, -7455066, 6338219],
	title:'Kjøretid (2 t. fra flyplass)',
	source: new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS': 'wms-havvind:havvind_flyplasser_kjoretid','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847'},
	ratio: 1,
	serverType: 'geoserver',
	visible: true,
	})
})

const havvind_kandidater_lok_hav_land_sc1_lt60 = new ImageLayer({
	//extent: [-13884991, 2870341, -7455066, 6338219],
	//title:'Hav og land samlet (<60m)',
	title:'Avstander <= 60m',
	source: new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS': 'wms-havvind:havvind_kandidater_lok_hav_land_sc1','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847','cql_filter':'distance<=60'},
	ratio: 1,
	serverType: 'geoserver',
	//visible: false,
	visible: true,
	})
})

const havvind_kandidater_lok_hav_land_sc1_gt60 = new ImageLayer({
	//extent: [-13884991, 2870341, -7455066, 6338219],
	//title:'Hav og land samlet (>60m)',
	title:'Avstander > 60m',
	source: new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS': 'wms-havvind:havvind_kandidater_lok_hav_land_sc1','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847','cql_filter':'distance>60'},
	ratio: 1,
	serverType: 'geoserver',
	//visible: false,
	visible: true,
	})
})

const havvind_kandidater_lok_hav_land_sc2_lt30 = new ImageLayer({
	//extent: [-13884991, 2870341, -7455066, 6338219],
	//title:'Hav og land samlet (<30m)',
	title:'Avstander <= 30m',
	source: new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS': 'wms-havvind:havvind_kandidater_lok_hav_land_sc2','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847','cql_filter':'distance<=30'},
	ratio: 1,
	serverType: 'geoserver',
	visible: true,
	})
})

const havvind_kandidater_lok_hav_land_sc2_gt30 = new ImageLayer({
	//extent: [-13884991, 2870341, -7455066, 6338219],
	title:'Hav og land samlet (>30m)',
	//title:'Avstander > 30m',
	title:'Avstander > 30m',
	source: new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS': 'wms-havvind:havvind_kandidater_lok_hav_land_sc2','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847','cql_filter':'distance>30'},
	ratio: 1,
	serverType: 'geoserver',
	visible: true,
	})
})


const havvind_kandidater_lok_sc1_lt60 = new ImageLayer({
	//extent: [-13884991, 2870341, -7455066, 6338219],
	//title:'Hav kandidater (<60m)',
	title:'Avstander <= 60m',
	source: new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS': 'wms-havvind:havvind_kandidater_lok_sc1','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847','cql_filter':'distance<=60'},
	ratio: 1,
	serverType: 'geoserver',
	//visible: false,
	visible: true,
	})
})

const havvind_kandidater_lok_sc1_gt60 = new ImageLayer({
	//extent: [-13884991, 2870341, -7455066, 6338219],
	//title:'Hav kandidater (>60m)',
	title:'Avstander > 60m',
	source: new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS': 'wms-havvind:havvind_kandidater_lok_sc1','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847','cql_filter':'distance>60'},
	ratio: 1,
	serverType: 'geoserver',
	//visible: false,
	visible: true,
	})
})

const havvind_kandidater_lok_sc2_lt30 = new ImageLayer({
	//extent: [-13884991, 2870341, -7455066, 6338219],
	//title:'Hav kandidater (<30m)',
	title:'Avstander <= 30m',
	source: new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS': 'wms-havvind:havvind_kandidater_lok_sc2','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847','cql_filter':'distance<=30'},
	ratio: 1,
	serverType: 'geoserver',
	visible: true,
	})
})

const havvind_kandidater_lok_sc2_gt30 = new ImageLayer({
	//extent: [-13884991, 2870341, -7455066, 6338219],
	//title:'Hav kandidater (>30m)',
	title:'Avstander > 30m',
	source: new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS': 'wms-havvind:havvind_kandidater_lok_sc2','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847','cql_filter':'distance>30'},
	ratio: 1,
	serverType: 'geoserver',
	visible: true,
	})
})

const havvind_dybdeareal_sc1 = new TileLayer({
	//extent: [-13884991, 2870341, -7455066, 6338219],
	title:'Dybdeareal (>100m)',
	source: new TileWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS': 'wms-havvind:havvind_dybdeareal_sc1','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847'},
	ratio: 1,
	serverType: 'geoserver',
	//visible: false,
	visible: true,
	})
})

const havvind_dybdeareal_sc2 = new TileLayer({
	//extent: [-13884991, 2870341, -7455066, 6338219],
	title:'Dybdeareal (>30m)',
	source: new TileWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS': 'wms-havvind:havvind_dybdeareal_sc2','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847'},
	ratio: 1,
	serverType: 'geoserver',
	visible: true,
	})
})

const havvind_dybdeareal_sc3 = new TileLayer({
	//extent: [-13884991, 2870341, -7455066, 6338219],
	title:'Dybdeareal (>20m)',
	source: new TileWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS': 'wms-havvind:havvind_dybdeareal_sc3','api_key':'f2f69fcd-602c-4015-86ae-8592b888a847'},
	ratio: 1,
	serverType: 'geoserver',
	visible: true,
	})
})

//var layers=[osm, havvind_kandidater_land, havvind_tettsteder, havvind_flyplasser]

var bg = new LayerGroup({
	title: 'Base map',
	//layers: [orthoophoto, osm, webatlas, webatlas_gray, webatlas_gray_cache],
	layers: [ orthoophoto, osm, webatlas, webatlas_gray, webatlas_gray_cache],
})


//avstand
var avstand_gt_sc1 = new LayerGroup({
	title: 'Avstand > 60m',
	fold: 'close',
	visible: true,
	//combine: true,
	layers: [havvind_kandidater_lok_hav_land_sc1_gt60, havvind_kandidater_lok_sc1_gt60]
})

var avstand_lt_sc1 = new LayerGroup({
	title: 'Avstand <= 60m',
	fold: 'close',
	visible: true,
	//combine: true,
	layers: [havvind_kandidater_lok_hav_land_sc1_lt60, havvind_kandidater_lok_sc1_lt60]
})

var avstand_gt_sc2 = new LayerGroup({
	title: 'Avstand > 30m',
	fold: 'close',
	visible: true,
	combine: true,
	layers: [havvind_kandidater_lok_hav_land_sc2_gt30, havvind_kandidater_lok_sc2_gt30]
})

var avstand_lt_sc2 = new LayerGroup({
	title: 'Avstand <= 30m',
	fold: 'close',
	visible: true,
	combine: true,
	layers: [havvind_kandidater_lok_hav_land_sc2_lt30, havvind_kandidater_lok_sc2_lt30]
})

var sc1 = new LayerGroup({
	title: 'Deep floater - Spar',
	fold: 'open',
	//visible: false,
	visible: true,
	//layers: [avstand_lt_sc1,avstand_gt_sc1, havvind_kandidater_land_scenario_1_v2, havvind_kandidater_lok_hav_land_sc1_lt60, havvind_kandidater_lok_hav_land_sc1_gt60, havvind_kandidater_lok_sc1_lt60, havvind_kandidater_lok_sc1_gt60, havvind_dybdeareal_sc1]
	//layers: [avstand_lt_sc1, avstand_gt_sc1, havvind_kandidater_land_scenario_1_v2, havvind_dybdeareal_sc1]
	layers: [havvind_kandidater_lok_sc1_lt60, havvind_kandidater_lok_sc1_gt60, havvind_kandidater_land_scenario_1_v2, havvind_dybdeareal_sc1]
})


var sc2 = new LayerGroup({
	title: 'Shallow floater - Semi',
	fold: 'open',
	//visible: false,
	visible: true,
	//layers: [avstand_lt_sc2,avstand_gt_sc2,havvind_kandidater_land_scenario_2_v2, havvind_kandidater_lok_hav_land_sc2_lt30, havvind_kandidater_lok_hav_land_sc2_gt30, havvind_kandidater_lok_sc2_lt30, havvind_kandidater_lok_sc2_gt30, havvind_dybdeareal_sc2]
	//layers: [avstand_lt_sc2, avstand_gt_sc2, havvind_kandidater_land_scenario_2_v2, havvind_dybdeareal_sc2]
	layers: [havvind_kandidater_lok_sc2_lt30, havvind_kandidater_lok_sc2_gt30, havvind_kandidater_land_scenario_2_v2, havvind_dybdeareal_sc2]
})


var over_lay = new LayerGroup({
	title: 'Kandidater group',
	fold: 'open',
	//visible: false,
	visible: true,
	//layers: [havvind_kandidater_land, havvind_tettsteder, havvind_flyplasser, havvind_kandidater_lok_hav_land_sc1, havvind_kandidater_lok_hav_land_sc2, havvind_dybdeareal_sc1, havvind_dybdeareal_sc2],
	//layers: [havvind_tettsteder, havvind_flyplasser, havvind_kandidater_land, sc2,sc1],
	layers: [havvind_flyplasser_kjoretid, havvind_tettsteder, havvind_kaibrygger ,havvind_flyplasser, havvind_dybdeareal_sc3, sc2,sc1],
})

var layers = [ bg, over_lay]
  
var ls = new LayerSwitcher({
	tipLabel: 'Legend', 
	groupSelectStyle: 'children', //'children', 'group', 'none'
	ascending: false,
	startActive: true
	})
//ls.showPanel();

var zoomslider = new ZoomSlider();

var sl = new ScaleLine({});

var fs = new FullScreen()

var mousePositionControl = new MousePosition({
	coordinateFormat: createStringXY(0),
	//projection: 'EPSG:4326',
	// comment the following two lines to have the mouse position
	// be placed within the map.
	//className: 'custom-mouse-position',
	//target: document.getElementById('mouse-position'),
	//undefinedHTML: '&nbsp;',
});


var map = new Map({
	controls: defaultControls().extend([ls, zoomslider, mousePositionControl, sl, fs]),
	//controls: [ls,new MousePosition()],
	//controls: [new MousePosition()],
	//controls: [ls, zoomslider],
	layers: layers,
	target: 'map',
	view: new View({
		center: [639131,8216789],
		zoom: 10,
	}),
});

// legend elemetns
const image_wms_legend0 = new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS':'wms-havvind:havvind_kandidater_land_scenario_1_v2','legend_options':'forceLabels:On'},
	serverType: 'geoserver',
	})
	
const image_wms_legend1 = new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS':'wms-havvind:havvind_dybdeareal_sc1','legend_options':'forceLabels:On'},
	serverType: 'geoserver',
	})

const image_wms_legend2 = new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS':'wms-havvind:havvind_dybdeareal_sc2'},
	serverType: 'geoserver',
	})

const image_wms_legend3 = new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS':'wms-havvind:havvind_kandidater_lok_sc1'},
	serverType: 'geoserver',
	})
	
const image_wms_legend4 = new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS':'wms-havvind:havvind_kandidater_lok_sc2'},
	serverType: 'geoserver',
	})

const image_wms_legend5 = new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS':'wms-havvind:havvind_kandidater_lok_hav_land_sc1'},
	serverType: 'geoserver',
	})
	
const image_wms_legend6 = new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS':'wms-havvind:havvind_kandidater_lok_hav_land_sc2'},
	serverType: 'geoserver',
	})
	
const image_wms_legend7 = new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS':'wms-havvind:havvind_tettsteder','legend_options':'forceLabels:On'},
	serverType: 'geoserver',
	})
	
const image_wms_legend8 = new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS':'wms-havvind:havvind_flyplasser','legend_options':'forceLabels:On'},
	serverType: 'geoserver',
	})
	
const image_wms_legend9 = new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS':'wms-havvind:havvind_kaibrygger','legend_options':'forceLabels:On'},
	serverType: 'geoserver',
	})
	
const image_wms_legend10 = new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS':'wms-havvind:havvind_dybdeareal_sc3','legend_options':'forceLabels:On'},
	serverType: 'geoserver',
	})

const image_wms_legend11 = new ImageWMS({
	//url: 'http://svm-geowms01.webdmz.no:8080/geoserver/wms-havvind/wms',
	url: 'https://waapi.webatlas.no/wms-havvind',
	params: {'LAYERS':'wms-havvind:havvind_flyplasser_kjoretid','legend_options':'forceLabels:On'},
	serverType: 'geoserver',
	})


var resolution = map.getView().getResolution();

var updateLegend = function (resolution) {
	var graphicUrl0 = image_wms_legend0.getLegendUrl(resolution);
	graphicUrl0 = graphicUrl0 + '&api_key=f2f69fcd-602c-4015-86ae-8592b888a847' + '&legend_options=forceLabels:On'
	
	var graphicUrl1 = image_wms_legend1.getLegendUrl(resolution);
	graphicUrl1 = graphicUrl1 + '&api_key=f2f69fcd-602c-4015-86ae-8592b888a847' + '&legend_options=forceLabels:On'
	
	var graphicUrl2 = image_wms_legend2.getLegendUrl(resolution);
	graphicUrl2 = graphicUrl2 + '&api_key=f2f69fcd-602c-4015-86ae-8592b888a847' + '&legend_options=forceLabels:On'
	
	var graphicUrl3 = image_wms_legend3.getLegendUrl(resolution);
	graphicUrl3 = graphicUrl3 + '&api_key=f2f69fcd-602c-4015-86ae-8592b888a847' + '&legend_options=forceLabels:On'

	var graphicUrl4 = image_wms_legend4.getLegendUrl(resolution);
	graphicUrl4 = graphicUrl4 + '&api_key=f2f69fcd-602c-4015-86ae-8592b888a847' + '&legend_options=forceLabels:On'
	
	var graphicUrl5 = image_wms_legend5.getLegendUrl(resolution);
	graphicUrl5 = graphicUrl5 + '&api_key=f2f69fcd-602c-4015-86ae-8592b888a847' + '&legend_options=forceLabels:On'
	
	var graphicUrl6 = image_wms_legend6.getLegendUrl(resolution);
	graphicUrl6 = graphicUrl6 + '&api_key=f2f69fcd-602c-4015-86ae-8592b888a847' + '&legend_options=forceLabels:On'
	
	var graphicUrl7 = image_wms_legend7.getLegendUrl(resolution);
	graphicUrl7 = graphicUrl7 + '&api_key=f2f69fcd-602c-4015-86ae-8592b888a847' + '&legend_options=forceLabels:On'
	
	var graphicUrl8 = image_wms_legend8.getLegendUrl(resolution);
	graphicUrl8 = graphicUrl8 + '&api_key=f2f69fcd-602c-4015-86ae-8592b888a847' + '&legend_options=forceLabels:On'
	
	var graphicUrl9 = image_wms_legend9.getLegendUrl(resolution);
	graphicUrl9 = graphicUrl9 + '&api_key=f2f69fcd-602c-4015-86ae-8592b888a847' + '&legend_options=forceLabels:On'
	
	var graphicUrl10 = image_wms_legend10.getLegendUrl(resolution);
	graphicUrl10 = graphicUrl10 + '&api_key=f2f69fcd-602c-4015-86ae-8592b888a847' + '&legend_options=forceLabels:On'
	
	var graphicUrl11 = image_wms_legend11.getLegendUrl(resolution);
	graphicUrl11 = graphicUrl11 + '&api_key=f2f69fcd-602c-4015-86ae-8592b888a847' + '&legend_options=forceLabels:On'
	

	var img0 = document.getElementById('legend0');
	var img1 = document.getElementById('legend1');
	var img2 = document.getElementById('legend2');
	var img3 = document.getElementById('legend3');
	var img4 = document.getElementById('legend4');
	var img5 = document.getElementById('legend5');
	var img6 = document.getElementById('legend6');
	var img7 = document.getElementById('legend7');
	var img8 = document.getElementById('legend8');
	var img9 = document.getElementById('legend9');
	var img10 = document.getElementById('legend10');
	var img11 = document.getElementById('legend11');

	img0.src = graphicUrl0;
	img1.src = graphicUrl1;
	img2.src = graphicUrl2;
	img3.src = graphicUrl3;
	//img4.src = graphicUrl4;
	//img5.src = graphicUrl5;
	//img6.src = graphicUrl6;
	img7.src = graphicUrl7;
	img8.src = graphicUrl8;
	img9.src = graphicUrl9;
	img10.src = graphicUrl10;
	img11.src = graphicUrl11;
};
updateLegend(resolution);
// Update the legend when the resolution changes
map.getView().on('change:resolution', function (event) {
	var resolution = event.target.getResolution();
	updateLegend(resolution);
});

// src =  'EPSG:3857'
//dest = 'EPSG:25833'

map.on('Xsingleclick', function (evt) {
	document.getElementById('info').innerHTML = '';
	var viewResolution = /** @type {number} */ (map.getView().getResolution());
	var url = image_wms.getFeatureInfoUrl(
		evt.coordinate,
		viewResolution,
		'EPSG:3857',
		{'INFO_FORMAT': 'text/html'}
	);
	if (url) {
		fetch(url)
		.then(function (response) { return response.text(); })
		.then(function (html) {
		document.getElementById('info').innerHTML = html;
		});
	}
});

map.on('Xpointermove', function (evt) {
	if (evt.dragging) {
		return;
	}
	var pixel = map.getEventPixel(evt.originalEvent);
	var hit = map.forEachLayerAtPixel(pixel, function () {
		return true;
	});
	map.getTargetElement().style.cursor = hit ? 'pointer' : '';
});

